@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,500&display=swap');
:root {
    --body-bg: #EFF2F5;
    --body-text: #6B7280;
    --bs-primary: #0058de;
    --bs-primary-hover: #1C6AE5;
    --bs-primary2: #3A86FF;
    --bs-primary2-hover: #347bee;
    --bs-secondary: #111827;
    --text-secondary: #111827;
    --primary-heading: #1F2937;
    --bs-white: #ffffff;
    --bs-dark-gray: #1F2937;
}
body{font-family:'Poppins', sans-serif;font-weight:400;font-weight:400;font-size:16px;color:var(--body-text);background-color:var(--body-bg);overflow-x:hidden}
a{color:var(--primary-color)}
a:hover{color:var(--primary-color);text-decoration:none}
.text-300{font-weight:300!important}
.text-400{font-weight:400!important}
.text-500{font-weight:500!important}
.text-600{font-weight:600!important}
.text-700{font-weight:700!important}
.text-900{font-weight:900!important}
.text-dark{color:var(--bs-gray-dark)}
.text-light{color:var(--bs-gray-dark)}
.fs-1{font-size:40px}
.fs-2{font-size:36px}
.fs-3{font-size:32px}
.fs-4{font-size:28px}
.fs-5{font-size:24px}
.fs-6{font-size:20px}
.btn-group-lg>.btn,.btn-lg{padding:12px 30px}
.text-justify{text-align:justify!important}
.h1,h1{font-size:36px}
.heading-black{color:var(--heading-black)}
.heading-white{color:var(--heading-white)}
.text-light{font-weight:300}
.text-regular{font-weight:400}
.text-medium{font-weight:500}
.text-bold{font-weight:600}
.text-extra-bold{font-weight:700}
.h2,.h3,.h4,.h5,.h6,h2,h3,h4,h5,h6{color:var(--heading-color);font-weight:500}
h6{line-height:1.5}
.btn{border-radius:10px}
.btn.btn-sm{padding:8px}
.btn-primary{background-color:var(--bs-primary);border-color:var(--bs-primary)}
.btn-check:focus+.btn-primary,.btn-primary:active,.btn-primary:focus{outline:0;box-shadow:none}
.btn-check:focus+.btn-primary,.btn-primary:focus{background:var(--bs-primary-hover);border-color:var(--bs-primary-hover);color:var(--bs-white);box-shadow:none}
.btn-primary:hover{background:var(--bs-primary-hover);border-color:var(--bs-primary-hover);color:var(--bs-white)}
.block-header .h1{font-weight:300;font-size:50px;color:var(--heding-black)}
button.navbar-toggler{font-size:30px;background:0 0;padding-left:5px;padding-right:5px;color:#fff;border:2px solid #fff;margin-bottom:5px}
.navbar button.navbar-toggler{font-size:16px;background-color:#fff;color:var(--text-primary)}
button.navbar-toggler:focus{outline:0;box-shadow:none}
.btn{border-radius:5px;padding:12px 20px}
.bg-primary{background-color:var(--bs-primary)!important}
.bg-light{background-color:var(--bs-light)!important}
.navbar-brand img{height:50px}
.navbar-expand-lg .navbar-nav .nav-link{padding-left:1.7rem;font-size:18px;font-weight:400;color:#404040}
.display-4{font-size:50px}
.display-4 strong{font-weight:600}
.block-header{display:block}
.block-header .h1{color:var(--text-secondary);font-size:40px;font-weight:600}
.block-header .h1 small{display:block;color:var(--text-light-gray);font-size:50%;font-weight:600}

#start-sche-form{padding:3rem 10rem}
.start-sche-inner .form-group .form-control{height:50px;border:none;min-width:300px}
.start-sche-inner .btn{height:50px;color:var(--text-secondary);padding-left:2.2rem;padding-right:2.2rem;font-weight:600}
.banner-head{background-position:center bottom;position:relative;text-align:center}
.banner-head .inner-header-content{height:400px;display:flex;justify-content:center;align-items:flex-end}
.inner-header-content{position:relative;z-index:1}
.header-title-block{background-color:#fff;padding:.5rem 3rem 0;border-radius:1rem;border-bottom-left-radius:0;border-bottom-right-radius:0}
.header-title-block .h5{font-weight:600;color:var(--text-primary);margin-bottom:.3rem}
.header-title-block a{text-decoration:none}
.header-title-block .breadcrumb-item{text-decoration:none;font-size:.9rem}


.phone-code-input .ngx-mat-tel-input-container{background:#f3f4f6;border:none;border-radius:8px;color:#6b7280;position:relative}
.phone-code-input .ngx-mat-tel-input-container button.mat-mdc-button{opacity: 1;}
.phone-code-input .ngx-mat-tel-input-container .mdc-button__ripple,.phone-code-input .ngx-mat-tel-input-container .mat-mdc-button-ripple{display:none;}
.phone-code-input .ngx-mat-tel-input-container input.mat-mdc-input-element{padding:0.8rem 1rem .8rem 98px;}
.mat-mdc-menu-panel.mat-mdc-menu-panel{max-height:240px;}
.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content{padding:0;}
/* .mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content input.country-search{border:none;} */
.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content .country-list-button{padding:8px 12px;}
.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content .country-list-button .icon-wrapper{padding-right:15px;}
.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content .mdc-list-item__primary-text{font-size:15px;}


/* new css here */
/* .main-body{padding-top:10rem} */
.form-container{background-color:var(--bs-white);border-radius:12px}
.form-img-block{background:#0058de;border-top-right-radius:12px;border-bottom-right-radius:12px;padding:30px 0 30px 30px;height:100%}
.form-img-block figure{max-width:350px;margin:20px auto 0;}
.display-1{font-weight:900;-webkit-text-stroke-width:1px;-webkit-text-stroke-color:var(--bs-white);color:transparent;opacity:60%;font-size:90px;margin-top:30px;text-transform:uppercase;text-align:center;}
/* .display-1 span{display:block} */
.form-heading{font-weight:600;color:var(--primary-heading)}
label{font-size:16px;color:#374151}
.form-footer{color:#374151}
.theme-link{color:var(--bs-primary);text-decoration:none;font-weight:500}
.signup-form-container{padding-top:3rem;padding-bottom:3rem}
.form-control{padding:.8rem 1rem;background-color:#f3f4f6;border:none;border-radius:8px;color:#6b7280}
.form-control:focus{color:#212529;background-color:#f3f4f6;border-color:var(--bs-primary);outline:0;box-shadow:none}
select.form-control{background:#f3f4f6 url(../img/downarrow.png) no-repeat center right;cursor:pointer}
input::placeholder{color:#6b7280}
label sup{color:red}
.password-group{position:relative}
.password-group i{position:absolute;right:16px;top:9px;font-size:21px}
.ngx-otp-input{width:44px!important;height:44px!important;border:1px solid #e5e7eb!important}
.dashboard-title-block .h3{font-weight:600;color:#1f2937;font-size:24px}
.recent-proposal-row .h5{color:var(--bs-dark-gray);font-size:16px;margin-bottom:0;font-weight:600;margin-bottom:5px}
.recent-proposal-row p{margin-bottom:0;color:#6b7280;font-size:13px}
.recent-proposal-row .row .col:first-child{max-width:24%;width:24%}
.recent-proposal-row .row .col:nth-child(2){max-width:28%;width:28%}
.recent-proposal-row .row .col:nth-child(3){max-width:48%;width:48%;text-align:right}
.user-sm-img{width:48px;height:48px;border-radius:50%;-webkit-border-radius:50%;-moz-border-radius:50%;overflow:hidden}
.userstatusbar{display:inline-block}

.user-sm-img img{width:100%;height:100%;object-fit:cover}
.ins-time-dtl .fs-6{font-size:14px!important}
.ins-time-dtl a{color:var(--bs-primary);margin-bottom:6px;display:inline-block}
#steps{width:480px;display:flex;align-items:center;margin-top:26px}
.step{width:24px;height:24px;background-color:#c1c6e5;display:inline-block;border:2px solid;border-color:#c1c6e5;border-radius:50%;color:#cdd0da;font-weight:600;text-align:center;line-height:24px}
.step:nth-child(n+2){margin:0 0 0 100px}
.step:nth-child(n+2):before{width:100px;height:3px;display:block;background-color:#c1c6e5;transform:translate(-102px,9px);content:""}
.step:after{width:160px;display:block;transform:translate(-78px,-35px);color:#4b5563;content:attr(data-desc);font-weight:400;font-size:14px;position:relative}
.step.active{border-color:var(--bs-primary);background:var(--bs-white)}
.step.active.done:before{background:var(--bs-primary)}
.step img{display:none}
.step.done{background-color:#fff;border-color:var(--bs-primary);position:relative}
.step.done img{position:absolute;left:1px;top:1px;width:18px;height:18px;display:block}
.step.done:before{background-color:#58bb58}
.step:first-child:after{top:3px}
.recent-proposal-row{background:#f9fafb;padding:2rem 1rem;margin-bottom:1.3rem;border-radius:10px}

.img_preview{display:flex;margin-top:12px;}
.img_preview .bx{width:80px;height:80px;position:relative;margin-right:10px;}
.img_preview .bx figure{width:100%;height:100%;background:#f5f5f5;margin: 0;
  border-radius: 6px;
  overflow: hidden;}
.img_preview .bx figure a{display:block;width:100%;height:100%;display:flex;align-items:center;justify-content:center;}
.img_preview .bx figure img{width:100%;height:80px;object-fit:cover;}
.img_preview .bx figure i{font-size:35px;}
.img_preview .bx .clos{width:18px;height:18px;border-radius:20px;display:flex;align-items:center;justify-content:center;position:absolute;right:-7px;top:-7px;background:#222;color:#fff;line-height:1;}
/* 31 May 2023 */


/* ---------- 20-04-2043 --------- */

.btn-dark{background:var(--bs-dark-gray);border-color:var(--bs-dark-gray)}
.squre-block-link{text-decoration:none;color:var(--bs-primary2)}
.squre-block-link figcaption{margin:0}
.squre-block{text-align:center;padding:2rem 1.5rem;background-color:#e4f2ff;border-radius:8px;height:100%;transition:all ease-in .2s}
.ins-icon-bx{width:53px;height:53px;display:inline-block;background:#3a86ff;border-radius:12px;display:flex;align-items:center;margin:0 auto 1rem auto;justify-content:center}
.input-form-head .btn{font-size:14px}
.squre-block-link.active .squre-block,.squre-block-link:focus .squre-block,.squre-block-link:hover .squre-block{background-color:var(--bs-primary2);color:#fff}
.squre-block-link.active .ins-icon-bx,.squre-block-link:focus .ins-icon-bx,.squre-block-link:hover .ins-icon-bx{background-color:var(--bs-primary)}
.contract-proposal-aside .h6{color:var(--bs-dark-gray);font-weight:600;font-size:16px}
p.sm-lead{font-size:14px;color:var(--body-text)}
.phone-aside-link{text-decoration:none}
.phone-aside-link span{color:var(--bs-primary);font-size:18px}
.offer-content{margin-left:-12px;margin-right:-12px;padding:0;background:#fff}
.offer-content .accordion-item:not(:first-of-type){border:none}
.offer-content .accordion-button{background:#fff;border:none;padding:1.3rem;border-radius:0;border-bottom:1px dashed #e5e7eb!important}
.offer-content .accordion-body{background:#f9fafb;padding-left:1.3rem!important;padding-right:1.3rem!important}
.offer-content .accordion-item:first-of-type{border:none}
.offer-content .accordion-item:last-of-type .accordion-button.collapsed{border:none}
.offer-content .accordion-button:not(.collapsed){box-shadow:none;border-bottom:1px dashed #e5e7eb!important;background:#f9fafb}
.block-seperator{border-bottom:1px dashed #e5e7eb;margin-top:1.6rem;margin-bottom:1.6rem}

/* css 21-04-2023 */
.offer-received-no,.policy-title{color:var(--bs-dark-gray);font-weight:600}
.offer-received-no{margin-bottom:.6rem}
.policy-title{font-size:18px}
.accordion-item:first-of-type .accordion-button{color:var(--body-text);font-size:16px}
.companyName{font-size:18px}
.company-logo{min-width:48px;height:48px;border-radius:5px;background-color:#ccc}
.company-logo img{width:100%;height:100%;object-fit:cover;border-radius:5px}
.plan-duration{position:relative}
.plan-duration:before{content:'';width:1px;background-color:#e5e7eb;height:38px;position:absolute;top:10px;left:50%}
.plan-duration small{font-size:13px;display:block;font-weight:500;margin-bottom:10px}
.plan-duration .plan-monthly,.plan-duration .plan-yearly{font-size:18px;font-weight:700;color:var(--bs-dark-gray)}
.plan-duration .plan-yearly{color:var(--bs-primary2)}
.plan-duration .plan-monthly small{color:var(--body-text)}
.type-policy{display:block;width:32px;height:32px;background:#eff5ff;display:flex;align-items:center;justify-content:center;border-radius:4px;margin-bottom:5px}
.type-policy img{max-height:18px}
.comparison-card ul{margin:0;padding:0}
.comparison-card .fs-6{font-weight:700;color:var(--bs-dark-gray);margin-bottom:1rem}
.comparison-card ul li{list-style:none;margin:0 0 1rem 0;padding:0;display:block;position:relative}
.comparison-card ul li:last-child{margin-bottom:0}
.comparison-card ul li i{color:var(--bs-primary2);margin-right:.8rem}
.comparison-card ul.document-offer li,.comparison-card ul.other-offer li{padding-left:1.8rem}
.comparison-card ul.document-offer li:before,.comparison-card ul.other-offer li:before{display:inline-block;font-family:bootstrap-icons!important;font-style:normal;font-weight:400!important;position:absolute;margin-top:-2px;left:0;color:var(--bs-primary2)}
.comparison-card ul.document-offer li:before{top:1px;content:"\f38a"}
.comparison-card ul.document-offer li span{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:172px;display:inline-block}
.comparison-card ul.document-offer li a{position:absolute;right:0;top:0;color:var(--body-text)}
.comparison-card ul.document-offer li a i{margin-right:0;color:var(--body-text)}
.comparison-card ul.other-offer li:before{top:2px;content:"\f26c"}
.comparison-card ul.dotted-list li{padding-left:2rem}
.comparison-card ul.dotted-list li:before{content:'';width:4px;height:4px;border-radius:50%;background-color:var(--bs-dark-gray);position:absolute;top:50%;margin-top:-2px;left:1rem}
.comparison-card .btn-primary{background:#e4f2ff;border-color:#e4f2ff;color:var(--bs-primary2)}
.bg-info{color:#21c1f3;background-color:#def5fd!important}
.btn-info.bg-info{border-color:#def5fd!important}
.btn-info.bg-info:hover{color:#06627e}
.modal-header{border-bottom:1px dashed #e5e7eb}
.modal-title{font-size:24px;font-weight:600;color:var(--bs-dark-gray)}
.modal-footer{padding:0 0 1.3rem 0;border:none}
#updateEmailOtp form.ngx-otp-input-container,#updatephoneOtp form.ngx-otp-input-container{justify-content:center!important}
.dash-divider{border-bottom:1px dashed #d1d5db;margin:3rem 0 1.3rem;display:block}
.custom-file{position:relative;font-family:arial;overflow:hidden;margin-bottom:10px;width:auto;display:block;padding:10px;height:60px;background-color:#f3f4f6;border-radius:6px}
label.custom-file-label{position:absolute;background:var(--bs-primary2);top:0;right:0;height:60px;line-height:60px;padding:0 1.3rem;color:var(--bs-white);font-size:16px!important}
.custom-file-input{position:absolute;left:0;top:0;width:100%;height:100%;cursor:pointer;opacity:0;z-index:100}
.custom-file img{display:inline-block;vertical-align:middle;margin-right:5px}
ul.file-list{list-style:none;padding:0}
.upload-doc-list li{padding:1rem;border:1px solid #ccc;border-radius:8px;position:relative;margin-bottom:1rem;color:var(--bs-dark-gray)}
.upload-doc-list .remove-list{cursor:pointer;margin-left:10px;position:absolute;right:1rem;top:1rem;color:var(--body-text)}
.contract-proposal-aside ul li{font-size:14px}
.stepper-multipleform .mat-horizontal-stepper-wrapper{display:flex;flex-direction:row}
.stepper-multipleform .mat-horizontal-stepper-header-container{display:block;padding:1rem;background:#fff;border:none;border-radius:10px;width:380px;margin-right:20px;max-height:calc(100vh - 300px);overflow:auto}
.mat-stepper-horizontal,.stepper-multipleform .mat-stepper-vertical{background:0 0}
.stepper-multipleform .mat-horizontal-content-container{background-color:#fff;padding:20px;width:calc(100% - 400px);border-radius:10px}
.stepper-multipleform .mat-horizontal-stepper-header{padding:0 0 12px 0;margin-bottom:12px;border-bottom:1px dashed #ccc}
.stepper-multipleform .mat-stepper-horizontal-line{display:none}
.stepper-multipleform input{display:block}
.mat-step-text-label span{font-weight:600}
.showInnerForm div.blockLight{padding:1.2rem 1.2rem 0;background-color:#fff;border:1px solid #e1e1e1;border-radius:10px;margin-bottom:1.5rem;box-shadow:0 0 15px rgba(0,0,0,.1)}
.link-primary-text{text-decoration:none;color:var(--bs-primary-hover)}
.link-primary-text i{margin-right:5px}
/* media css */


/* mat css customize here */
.mat-step-header .mat-step-icon,.mat-step-header .mat-step-icon-selected,.mat-step-header .mat-step-icon-state-done,.mat-step-header .mat-step-icon-state-edit{background:#fff;color:#20c7c7;border-radius:4px;width:25px;height:25px;line-height:25px;border:1px solid #20c7c7}
.mat-stepper-horizontal-line{border-top-style:dashed!important;border-color:#a5afbd!important}
.mat-step-header .mat-step-icon-selected,.mat-step-header .mat-step-icon-state-done,.mat-step-header .mat-step-icon-state-edit{border:1px solid #119d9d;background:#20c7c7;color:#fff}
.mat-mdc-text-field-wrapper{border:none;height:50px!important}
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-floating-label{display:none}
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{padding-top:12px}
.mat-mdc-form-field{display:block!important}
.mdc-text-field--filled:not(.mdc-text-field--disabled){background:#f3f4f6;border-radius:8px}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before{border-bottom-color:rgba(0,0,0,.42);display:none}
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay{opacity:.01}
.mat-mdc-button.mat-primary,.mat-mdc-outlined-button.mat-primary{background:var(--bs-primary);color:var(--bs-white)!important;height:50px;border-radius:8px;font-size:16px;font-weight:400}
.mat-mdc-checkbox .mdc-checkbox__ripple{opacity:0;display:none}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background{border-color:var(--bs-primary)!important;background-color:var(--bs-primary)!important}
.signup-form-container .mat-horizontal-content-container{padding:0}
/* .signup-form-container .mat-horizontal-stepper-header-container{margin-left:-24px} */
.mat-horizontal-stepper-header{height:auto!important}
.mat-step-header.cdk-keyboard-focused,.mat-step-header.cdk-program-focused,.mat-step-header:hover:not([aria-disabled]),.mat-step-header:hover[aria-disabled=false]{background-color:transparent!important}
.mat-step-header .mat-step-header-ripple{display:none}
.mat-horizontal-stepper-wrapper .mat-stepper-horizontal-line{min-width:12px;width:12px;display:none;}
.mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header-container{padding:0 4px}
.mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header .mat-step-icon{margin-right:5px}
.mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header .mat-step-label,.mat-step-label.mat-step-label-active.mat-step-label-selected{font-size:13px}


/* admin css */
.right-block{margin-left:calc(290px + 20px);padding:20px}
.dashboard-content{padding:20px;border-radius:10px;background-color:var(--bs-white);margin-bottom:20px;margin-top:110px}

.dashboard-card .card{height:210px;border-radius:10px}
.dashboard-card .card .card-body{display:flex;flex-direction:column;justify-content:space-between}
.dashboard-card .card-footer{border:none;padding:0;background:0 0}
.dashboard-card .card-footer .count-number{font-size:36px;font-weight:600;line-height:1.2}
.dashboard-card .card-footer .card-title{color:#374151;margin-bottom:0}
.card.bg-primary{background-color:#e4f2ff!important;border:none}
.card.bg-primary .card-footer .count-number{color:#3a86ff}
.card.bg-secondary .card-footer .count-number{color:#00d3f7}
.card.bg-info .card-footer .count-number{color:var(--bs-primary)}
.card.bg-warning .card-footer .count-number{color:#ff3a86}
.card.bg-secondary{background-color:#d9faff!important;border:none}
.card.bg-info{background-color:#efe8ff!important;border:none}
.card.bg-warning{background-color:#ffe5ee!important;border:none}
.card-icon{width:40px;height:40px;display:inline-block;background-image:url(../img/dash-card-icon.png);background-repeat:no-repeat}
.card-icon.personalised-dash{background-position:-48px 0}
.card-icon.add-contract-dash{background-position:-96px 0}
.card-icon.added-doc-dash{background-position:-144px 0}
.left-block{position:fixed;top:20px;left:20px;bottom:20px;width:290px;z-index:111;transition:all .3s;padding:0;background-color:var(--bs-primary);border-radius:10px;min-height:calc(100vh - 40px)}
.sidebar-logo{border-bottom:1px solid rgba(243,244,246,.4);margin-bottom:20px;padding:1rem 1.2rem 1rem}
.sidebar-logo img{max-height:45px;}
.left-block aside{width:290px;display:flex;flex-direction:column;justify-content:space-between;height:100%}


.aside ul{margin:0;padding:0}
.aside ul li{padding:0;margin:0;list-style:none}
.aside ul li a{display:block}
.invalid{color:red;font-size:12px;margin-top:5px}
.btn.disabled,.btn:disabled,fieldset:disabled .btn{color:var(--bs-secondary);background:#ddd;border-color:#ddd}
.custom-table table{font-size:14px;color:#374151}
.custom-table table thead tr th{color:#6b7280;border-bottom:1px dashed #ccc;font-weight:400}
.custom-table table tbody tr td,.custom-table table thead tr th{padding-left:.5rem;padding-right:.5rem;padding-top:1rem;padding-bottom:1rem;vertical-align:middle}
.custom-table table tbody tr td:first-child,.custom-table table thead tr th:first-child{padding-left:0}
.custom-table table tbody tr td:last-child,.custom-table table thead tr th:last-child{padding-right:0}
.custom-table table tbody tr td:last-child,.custom-table table thead tr th:last-child{text-align:right}
.custom-table table tbody tr td{border:none;padding-top:.8rem;padding-bottom:.8rem}
.custom-table table tbody tr td:last-child a{text-decoration:none;margin-left:10px}
.custom-table table tbody tr td .dropdown-menu{padding:0;box-shadow:0 0 30px rgba(0,0,0,.2);border:none}
.custom-table table tbody tr td .dropdown-item{font-size:14px;padding:.5rem 1rem .5rem 2.2rem}
.custom-table table tbody tr td .dropdown-item:hover{background-color:#f6f6f6;color:var(--bs-secondary)}
.update-prof-content{padding-left:1.3rem}
.badge{font-weight:500;padding:.8rem 1rem;font-size:13px}
.bg-success{color:#05a357;background-color:#e5f6eb!important}
.bg-danger{color:#e11900;background-color:#fde7e6!important}
.bg-light{color:var(--bs-secondary);background-color:#f3f4f6!important}
.downloadInvoice{background:url(../img/download-invoice.png) no-repeat 10px center}
.downloadPolicy{background:url(../img/policy.png) no-repeat 10px center}
.cus-table-pagination .pagination{justify-content:end}
.cus-table-pagination .pagination .active>.page-link,.cus-table-pagination .pagination .page-link.active{z-index:3;color:var(--bs-white);background-color:var(--bs-primary);border-color:var(--bs-primary)}
.form-search-block{display:inline-block;position:relative}
.form-search-block input[type=search],.form-search-block input[type=text]{width:300px}
.form-search-block button{position:absolute;left:0;top:0;background:0 0;border:none;padding:.8rem 1rem}
.form-search-block input{padding-left:2.6rem}
.dotted-btn{border:none;padding:0;background:0 0;color:#6b7280;font-size:22px;line-height:1.1}
.dropdown-toggle.dotted-btn::after{display:none}
.w-select{width:160px}
.offcanvas-title{font-weight:600;color:#1f2937;font-size:24px}
.edit-profile-offcanvas{width:520px!important;border-top-left-radius:20px;border-bottom-left-radius:20px;padding:1rem}
.plans{display:-webkit-box;display:-ms-flexbox;display:flex;background:#fff;-ms-flex-align:center;align-items:center;-ms-flex-wrap:wrap;flex-wrap:wrap}
.plans .plan input[type=radio]{position:absolute;opacity:0}
.plans .plan{cursor:pointer;width:140px;height:160px}
/* .plans .plan:last-child{cursor:pointer;width:140px;height:160px} */
.plans .plan .plan-content{display:block;padding:20px;border:2px solid #e1e2e7;border-radius:10px;-webkit-transition:-webkit-box-shadow .4s;transition:-webkit-box-shadow .4s;-o-transition:box-shadow .4s;transition:box-shadow .4s;transition:box-shadow .4s,-webkit-box-shadow .4s;position:relative;text-align:center}
.plans .plan .plan-details{margin-top:.6rem}
.plans .plan .plan-content:hover{-webkit-box-shadow:0 3px 5px 0 #e8e8e8;box-shadow:0 3px 5px 0 #e8e8e8}
.plans .plan input[type=radio]:checked+.plan-content:after{content:"";position:absolute;height:8px;width:8px;background:var(--bs-primary);right:20px;top:20px;border-radius:100%;border:3px solid #fff;-webkit-box-shadow:0 0 0 2px var(--bs-primary);box-shadow:0 0 0 2px var(--bs-primary);display:none}
.plans .plan input[type=radio]:checked+.plan-content{border:2px solid var(--bs-primary);background:#eaf1fe;-webkit-transition:ease-in .3s;-o-transition:ease-in .3s;transition:ease-in .3s;color:var(--bs-primary)}
.cusCheckbox{display:flex}
.cusCheckbox .form-check{display:inline-block}

.bx_dvdr .points ul,.bx_dvdr .points ol,.bx_dvdr .points{margin:0;padding:0;list-style-type:none}
.bx_dvdr .points li{margin-bottom:10px;padding-left:28px;position:relative;list-style-type:none;}
.bx_dvdr .points li:before{content:'\F26C';font-family:bootstrap-icons;position:absolute;left:0;top:0;color:#0058de}
.bx_dvdr.ftrs .points li:before{content:'';left:6px;top:7px;background:#0058de;width:8px;height:8px;border-radius:8px}

.comparison-card .points ul,.comparison-card .points ol,.comparison-card .points dl{padding-left:15px;}
.comparison-card .points ul li{list-style-type:initial;position:relative;}
.comparison-card .points li{margin-bottom:8px;}
.comparison-card .points li:last-child{margin-bottom:0;}
.comparison-card .points ul li:before{content:'';width:8px;height:8px;background:var(--bs-primary);position:absolute;left:-15px;top:8px;border-radius:15px;}
.cursor-disable {cursor: not-allowed!important;}
.pswd_vis{position:relative;z-index:1;background:#f3f4f6;border-radius:4px}
.pswd_vis input{padding-left:40px}
.pswd_vis i{position:absolute;left:15px;top:16px;line-height:1;font-size:17px;color:#6b7280}

.ag-theme-alpine .ag-root-wrapper{border:none;}
.ag-theme-alpine .ag-header{border:none;}
.ag-theme-alpine .ag-row{border-bottom:2px dashed #e5e7eb;}
.ag-theme-alpine .ag-row .ag-cell,.ag-theme-alpine .ag-header-row .ag-header-cell{font-weight:400;color:var(--theme-color);font-size:14px;padding:11px 8px;height:auto;font-family:'Poppins', sans-serif;}
.ag-theme-alpine .ag-header-row .ag-header-cell{padding:15px 8px;}
.ag-theme-alpine .ag-cell-label-container{padding:0;}
.ag-checkbox-input-wrapper:focus-within, .ag-checkbox-input-wrapper:active{box-shadow:none;}
.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-ltr .ag-cell-range-single-cell, .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-rtl .ag-cell-range-single-cell, .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle{border-color:transparent;}
.ag-theme-alpine .ag-overlay-panel{align-items:center;justify-content:center;}
.ag-theme-alpine .ag-overlay-wrapper{font-family:'Poppins', sans-serif;position:relative;width:auto;height:50px;padding-top:80px;font-size:15px;color:#777;}
.ag-theme-alpine .ag-overlay-wrapper:before{content:'\F3C2';font-family:bootstrap-icons;display:block;font-size:45px;position:absolute;left:0;right:0;top:0;}
.ag-theme-alpine .ag-paging-panel{border:none;justify-content:center;font-family:'Poppins', sans-serif;}
.ag-theme-alpine .ag-paging-panel .ag-paging-number{font-weight:600;}
.ag-theme-alpine .ag-paging-row-summary-panel{display:none;}
.ag-theme-alpine .ag-paging-page-summary-panel{margin:0;}
.ag-row .ag-cell.actionDot{overflow:visible;}
.ag-row .ag-cell.actionDot .ag-cell-wrapper{display:block;width:40px;}
.ag-theme-alpine .ag-header-row .ag-header-cell{padding:15px 8px;font-weight:500}
label{width:100%}
.iti{display:block}
.iti input,.iti input[type=tel],.iti input[type=text]{width:100%!important;padding-top:13px;padding-bottom:13px;border:none;background:#f3f4f6;border-radius:8px}
.search-container input{width:calc(100% - 25px)!important;border:none}
.country-dropdown{border:none!important}
.iti input#country-search-box:focus-visible,.iti input:focus-visible,.iti input[type=tel]:focus-visible,.iti input[type=text]:focus-visible{outline:0}
.form-registration{padding:60px 0;}
.form-registration .form-control.ng-valid,.form-registration .phone-code-input .ng-valid .mat-mdc-text-field-wrapper{background:#e9f0ff;border-color:#d1d9eb}
.ag-cell-wrapper{display:flex;align-items:center}
.ag-theme-alpine .ag-row .ag-cell span{line-height:1}
.ag-theme-alpine .ag-row .ag-cell span>span>span{display:block;margin-top:8px;opacity:.7;font-size:13px}
.ag-selection-checkbox{height:auto!important}
.offer-header .badge.accept {color:#05A357}
.offer-header .badge.accept:before{background:#05A357;}
.offer-header .badge.reject{color:#E11900}
.offer-header .badge.reject:before{background:#E11900}
.offer-header .badge.pending{color:#8a6b0b;}
.offer-header .badge.pending:before{background:#8a6b0b;}
.offer-header .badge.saved{color:var(--theme-color);}
.offer-header .badge.saved:before{background:var(--theme-color);}
.offer-header .badge.closed{color:#0a620c;}
.offer-header .badge.closed:before{background:#0a620c;}
.offer-header .badge{font-size:13px;float:right;font-weight:500;padding:0 0 0 12px}
.breadcrumb .breadcrumb-item{padding-right:22px;position:relative;margin-right:0;font-size:15px;text-transform:capitalize}
.breadcrumb .breadcrumb-item a{text-decoration:none}
.breadcrumb .breadcrumb-item a:after{content:"\f285";font-family:bootstrap-icons;right:0;position:absolute;font-size:13px;top:2px;text-transform:capitalize}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag{background:0 0}
ngx-intl-tel-input .iti.iti--allow-dropdown{display:flex;background:#f3f4f6;border-radius:8px}
ngx-intl-tel-input .iti.iti--allow-dropdown .iti__flag-container{position:static}
ngx-intl-tel-input .iti.iti--allow-dropdown .country-dropdown{width:100%;background:#f5f5f5;padding:10px}
ngx-intl-tel-input .iti.iti--allow-dropdown input.custom{background:0 0;padding:0!important;height:50px}
ngx-intl-tel-input .iti.iti--allow-dropdown .country-dropdown .search-container input{width:100%!important;background:#fff;border-radius:5px;margin-bottom:5px!important;border:2px solid #eee;height:44px;padding-left:12px!important;font-size:14px}
.country-dropdown .iti__country-list{max-height:155px;box-shadow:none}
.iti__country-list li.iti__country{font-size:13px}
.btn-transparent{background:0 0;border-color:transparent;box-shadow:none;color:var(--text-daner);font-weight:500;border:1px solid rgba(255,0,0,.2)}
.btn-transparent,.btn-transparent:hover{border:1px solid rgba(255,0,0,.5);color:#c40000;background:transparent;}

body.modal-open{padding:0 !important}
.privacy-modal .modal-content{border:none;border-radius:5px}
.privacy-modal .modal-header{border-bottom:2px solid #f2f2f2;padding:1.5rem}
.privacy-modal .modal-header .h5{margin:0;display:flex;align-items:center;font-weight:600;color:#333}
.privacy-modal .modal-header .h5 i{margin-right:10px;color:var(--bs-primary);font-size:28px}
.privacy-modal .modal-body{padding:1.5rem;}
.privacy-modal .modal-body .custom-scrl{overflow-y:auto;display:block;max-height:400px;padding-right:30px;}
.privacy-modal .modal-body .h3{font-size:22px;font-weight:600;color:#333}
.privacy-modal .modal-body .h4{font-size:18px;font-weight:600;color:#444;margin-bottom:12px}
.privacy-modal .modal-body p{font-size:14px}
.privacy-modal .modal-body p i{color:#444}
.privacy-modal .modal-body .points li a,.privacy-modal .modal-body p a{color:var(--bs-primary);text-decoration:none}
.privacy-modal .modal-body .points li a:active,.privacy-modal .modal-body .points li a:focus,.privacy-modal .modal-body .points li a:hover,.privacy-modal .modal-body p a:active,.privacy-modal .modal-body p a:focus,.privacy-modal .modal-body p a:hover{text-decoration:underline}
.privacy-modal .modal-body p a:focus-visible{outline:0}
.privacy-modal .modal-body .points{list-style-type:none;margin:0 0 20px;padding:0}
.privacy-modal .modal-body .points li{position:relative;font-size:14px;margin-bottom:10px;padding-left:20px}
.privacy-modal .modal-body .points li strong,.privacy-modal .modal-body .points li u{color:#444;font-weight:500}
.privacy-modal .modal-body .points li:before{content:'\F135';font-family:bootstrap-icons;position:absolute;color:#444;left:0;top:0;font-size:20px;line-height:1}
.privacy-modal .modal-content{border:none;border-radius:5px}
.privacy-modal .modal-footer{padding:0 1.5rem 2rem;justify-content:start}
.privacy-modal .modal-footer button{margin:0;border:2px solid var(--bs-primary);border-radius:0;padding:0 35px;line-height:50px;font-weight:600}
.privacy-modal .ng-scroll-viewport{padding-right:25px}
.privacy-modal .ng-scrollbar-track{background:rgba(0,0,0,.1)}
.privacy-modal .ng-scrollbar-thumb{background:#000}



/* html[lang="fr-CA"] .form-registration .display-1{font-size:70px;margin-top:-185px;} */
.signup-form-container .mat-horizontal-stepper-header{padding:0 10px;width:calc(100% / 3);}
.mat-step-icon .mat-icon,.signup-form-container  .mat-step-icon-state-error .mat-icon{font-size:16px;display:flex;align-items:center;justify-content:center;}
.signup-form-container .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header:first-child{padding-left:0;}
.signup-form-container .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header:last-child{padding-right:0;}
.signup-form-container .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-label{width:calc(100% - 30px);}
.ngx-otp-input{font-size:16px !important;}
.chat_cnt .dashboard-content{background:transparent;padding:0;}
.offcanvas-header .btn-close:focus{box-shadow:none;}
.accordion-header button:focus{box-shadow:none;}

.custom_file{position:relative;/*background:#f3f4f6;border-radius:8px;height:50px;display:flex;align-items:center;*/display: inline-block;}
.custom_file input[type="file"]{position:absolute;width:100%;height:100%;left:0;top:0;opacity:0;}
.custom_file button{width:auto;background:var(--bs-primary);color:#fff;border-radius:8px;padding:0 20px;height:50px;border:none;}
/* .custom_file span{color:#999;} */

.privacy-modal .btn-close,.privacy-modal .btn-close:focus{box-shadow:none;}


.file-type-two{padding:10px;background:#f9f9f9;border:1px dotted #ddd;position:relative;border-radius:10px;display:flex;justify-content:center;align-items:center;}
.file-type-two i{font-size:30px;line-height:1;margin-right:10px;}
.file-type-two span{line-height:32px;font-size:17px;}
.file-type-two span span{color:var(--bs-primary);font-weight:500;}
.file-type-two input[type="file"]{position:absolute;left:0;top:0;width:100%;height:100%;opacity:0;}



@media screen and (max-width: 1400px) {
  .squre-block{padding:1rem;font-size:14px}
}

@media screen and (max-width: 1360px) {
  .left-block,.left-block aside{width:260px}
  .right-block{margin-left:calc(260px + 20px)}
  .dashboard-header{left:300px}
  #steps{width:430px}
}

@media screen and (max-width: 1290px) {
  .step::after{width:70px;transform:translate(-28px,-35px);line-height:1.1;margin-top:-2px}
  .step:nth-child(n+2){margin:0 0 0 70px}
  .step:nth-child(n+2)::before{width:70px;transform:translate(-72px,9px)}
  #steps{width:320px}
}

@media screen and (max-width: 1399px) {
  .block-header .h1{font-size:34px}
}

@media screen and (max-width: 1199px) {
  /* html[lang="fr-CA"] .form-registration .display-1{font-size:60px;margin-top:-155px;line-height:1.3;} */
  .dashboard-title-block .h3{font-size:22px;}
  .dashboard-card .card{height:180px;}
}

@media screen and (max-width: 991px) {
  .form-registration{padding:50px 0;}
  .navbar-expand-lg .navbar-nav .nav-link{padding-left:0}
  body{font-size:15px;}
  .dashboard-header .navbar-nav .dropdown-menu{position:absolute;}
  .dashboard-title-block .h3{font-size:20px;}
  .offcanvas-title{font-size:22px;}
}

@media screen and (max-width: 767px) {
  .navbar-brand img{height:35px}
  .left-block{ -webkit-transition:background .5s ease-out;-moz-transition:background .5s ease-out;-o-transition:background .5s ease-out;transition:background .5s ease-out;background:rgba(0,0,0,.1);}
  .left-block aside{background:var(--bs-primary);}
  .left-block, .left-block aside{left:-300px;}
  .right-block{margin-left:0;}
  .left-block.show-leftbar, .left-block.show-leftbar aside{left:0;top:0;}
  .left-block.show-leftbar{border-radius:0;height:100vh;z-index:1000;width:100%;}
  .sidebar-logo{display:flex;justify-content:space-between;align-items:center;}
  .edit-profile-offcanvas{padding:5px;}
  .offcanvas-title{font-size:20px;}
}

@media screen and (max-width: 575px) {
  .form-registration{padding:40px 0;}
  body,.breadcrumb .breadcrumb-item{font-size:14px;}
  .ngx-otp-input{font-size:15px !important;}
  .right-block{padding:15px;}
  .offcanvas-title{font-size:19px;}
  .edit-profile-offcanvas{width:450px !important;}
  .offcanvas-body label,.offcanvas-body input,.offcanvas-body select{font-size:15px;}
  .dashboard-title-block .h3{font-size:19px;}
}

@media screen and (max-width: 479px) {
  .ngx-otp-input{font-size:14px !important;}
  .offcanvas-title{font-size:17px;}
  .edit-profile-offcanvas{width:360px !important;}
}

@media screen and (max-width: 400px) {
  .dashboard-content{padding:15px;}
  .ngx-otp-input{width:34px !important;height:34px !important}
  .lang-dropdown .dropdown-toggle{margin-left:0 !important;}
  .edit-profile-offcanvas{width:300px !important;}
  .plans .plan{width:125px;height:auto;margin-bottom:20px;}
  .pro_btns button{width:100%;margin:5px 0 !important;}
  .plans .plan .plan-content{padding:10px 20px;}
  .offcanvas-body label,.offcanvas-body input,.offcanvas-body select{font-size:14px;}
  .dashboard-title-block .h3{font-size:18px;}
}
