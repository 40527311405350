@import "~ag-grid-community/styles//ag-grid.css";
@import "~ag-grid-community/styles//ag-theme-alpine.css";
.dashboard-header .navbar {
  margin: 0;
  padding: 0;
}
.dashboard-header .navbar .container-fluid {
  margin: 0;
  padding: 0;
}
/* .aside-navbar ul {
  margin:0;
  padding:1rem 1.2rem;
}
.aside-navbar ul li {
  margin: 0 0 2px 0;
  padding:0;
  list-style: none;
}
.aside-navbar ul li.dash-active a, .aside-navbar ul li a:hover, .aside-navbar ul li a:focus, .aside-navbar ul li .accordion-item .accordion-button:focus {
  background-color: #4730F5;
  border-radius: 8px;
  box-shadow: none;
  border: none;
  outline: none;
}
.aside-navbar ul li a, .aside-navbar ul li .accordion-item {
  background: none;
  border: none;
}
.aside-navbar ul li a, .aside-navbar ul li .accordion-item .accordion-button {
  color:#fff;
  text-decoration: none;
  font-weight: 400;
  padding:1rem 1rem 1rem 2.5rem;
  display: block;
  transition: all ease-in 0.2s;
  background: none;
  position: relative;
}
.aside-navbar ul li .accordion-item .accordion-button:after {
  position: absolute;
  fill: white;
  filter: invert(1);
  filter: invert(1) sepia(1) saturate(1) hue-rotate(180deg);
  background-size: 16px;
}
.aside-navbar ul li .accordion-item .accordion-button.collapsed:after {
  right: 6px;
  top: 19px;
}
.aside-navbar ul li .accordion-item .accordion-button:after {
  right: 10px;
  top: 16px;
}
.aside-navbar ul li a:before, .aside-navbar ul li .accordion-item .accordion-button:before {
  content: '';
  background-image: url(assets/img/sidebar-icon.png);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 16px;
  top: 19px;
}
.aside-navbar ul li a:before, .aside-navbar ul li .accordion-body {
  padding:0;
}
.aside-navbar ul li.dashboard a:before {
  background-position: -37px 0;
}
.aside-navbar ul li.contract-proposal .accordion-item .accordion-button:before {
  background-position: -37px 0;
}
.aside-navbar ul li.message a:before {
  background-position: -72px 0;
}
.aside-navbar ul li.update-profile a:before {
  background-position: -106px 0;
}
.aside-navbar ul li.logout a:before {
  background-position: -140px 0;
} */
.dashboard-card .card {
  height: 210px;
  border-radius: 10px;
}
.dashboard-card .card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-card .card-footer {
  border: none;
  padding:0;
  background: none;
}
.dashboard-card .card-footer .count-number {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
}
.dashboard-card .card-footer .card-title {
  color: #374151;
  margin-bottom: 0;
}
.card.bg-primary {
  background-color: #E4F2FF !important;
  border: none;
}
.card.bg-primary .card-footer .count-number {
  color: #3A86FF;
}
.card.bg-secondary .card-footer .count-number {
  color: #00D3F7;
}
.card.bg-info .card-footer .count-number {
  color: #513AFF;
}
.card.bg-warning .card-footer .count-number {
  color: #FF3A86;
}
.card.bg-secondary {
  background-color: #D9FAFF !important;
  border: none;
}
.card.bg-info {
  background-color: #EFE8FF !important;
  border: none;
}
.card.bg-warning {
  background-color: #FFE5EE !important;
  border: none;
}
.card-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-image: url(assets/img/dash-card-icon.png);
  background-repeat: no-repeat;
}
.card-icon.personalised-dash {
  background-position: -48px 0;
}
.card-icon.add-contract-dash {
  background-position: -96px 0;
}
.card-icon.added-doc-dash {
  background-position: -144px 0;
}

.mat-mdc-dialog-actions{
  justify-content: center !important;
}



